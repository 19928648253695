.form-availability-wrapper {
    width: 100%;

    .availability {
        border-radius: 24px;
        background: $white;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;

        .input-content {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;

            .input-wrapper {
                width: 100%;

                .selectbox {
                    max-width: 100% !important;
                }
            }
        }

        .group {
            .input-wrapper:first-child {
                margin-right: 16px;
            }
        }
        
        .input-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 32px;
            
            p {
                margin-bottom: 8px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }

            label {
                color: #1E1E1E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 8px;
            }
    
            input[type="number"] {
                border-radius: 8px;
                border: 1px solid #777;
                padding: 16px;
    
                color: var(--text, #1E1E1E);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
    
        }

        .bi-button-primary{
            width: 50%;
            margin-top: 16px;
        }
    }

    #instalacionSelect {
        min-width: 240px;
        width: 100%;
    }

    @media (max-width: 800px) {

        div#input-content-selectbox {
            flex-direction: column;
        }
    }
}

.form-reserve-wrapper {

    .input-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 32px;

        label {
            color: #1E1E1E;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 8px;
        }

        input {
            border-radius: 8px;
            border: 1px solid #777;
            padding: 16px;

            color: var(--text, #1E1E1E);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }

    }

    .group-form {
        display: flex;
        justify-content: space-between;

        .input-wrapper:nth-child(2) {
            margin-left: 24px;
        }
    }

    .title {
        color: #1E1E1E;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 32px;
    }

    .light {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 32px;
    }

    .pay-content {
        width: 58%;
    }

    .group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .light {
            margin: 0;
        }

        .price-total {
            border-radius: 8px;
            padding: 8px;
            background: var(--Isidoro-Orange, #FF6200);
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        .price-dscto {
            border-radius: 8px;
            border: 1px solid var(--Isidoro-Orange, #FF6200);
            padding: 8px;
            color: var(--Isidoro-Orange, #FF6200);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
    }


    .button {
        display: flex;
        justify-content: end;
        margin-bottom: 32px;
    }

    .group-form .input-wrapper .placeholder {
        display: none;
    }

    @media (max-width: 800px) {

        .bi-button-primary {
            bottom: 0 !important;
            width: 100% !important;
        }

        .group-form .input-wrapper label {
            display: none;
        }

        .group-form .input-wrapper #placeholder::placeholder {
            display: initial;
            color: #333;
        }
    }
}