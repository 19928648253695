.checkbox-container{
    width: 24px;
    height: 24px;
    position: relative;
    margin: 16px;
    .check-wrapper {
        display: block;
        position: relative;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        input {
            display: none;
        }
        input:checked~.customCheck {
            background: url("../../images/icons/checkbox-checked.svg") no-repeat;
            background-size: 24px 24px;
        }
    }
    .customCheck {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background: url("../../images/icons/checkbox-default.svg") no-repeat;
        background-size: 24px 24px;
        transition: all .5s;
    }
}