#slider {
    width: 560px;
    max-width: 100vw;
    height: 750px;
    border-radius: 35px;
    margin: auto;
    position: relative;
    overflow: hidden;

    .list {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        width: max-content;
        transition: 1s;

        img {
            width: 560px;
            max-width: 100vw;
            height: 100%;
            object-fit: cover;
        }
    }

    .buttons {
        position: absolute;
        top: 47%;
        left: 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        button {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #fff5;
            margin: 0;
            color: #fff;
            border: none;
            font-family: 'Courier New', Courier, monospace;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .dots {
        position: absolute;
        bottom: 24px;
        color: #fff;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
            width: 15px;
            height: 10px;
            background-color: #fff;
            margin: 8px;
            border-radius: 10px;
            cursor: pointer;
            transition: 1s;
        }

        li.active {
            width: 52px;
        }

    }
}


@media screen and (max-width: 768px) {
    .slider {
        width: 100%;
        height: 400px;
    }
}