.selectbox{
    position: relative;
    max-width: 240px;
    width: 100%;
    cursor: pointer;
    .select, .active{
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid $grey2;
        p{
            margin-bottom: 0 !important;
            transition: 1s;
            color: $grey1;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
        span{
            margin-left: 16px;
        }
    }
    .active{
        background-color: $noche;
        color: $white;
    }
    .options{
        position: absolute;
        z-index: 10;
        left: 0;
        width: 100%;
        margin-top: 3px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        transition: all .5s;
        .option, .selected{
            display: flex;
            width: 100%;
            margin: 0 auto;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            background-color: #F7F6F6;
            color: #1E1E1E;
            text-align: left;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 0 !important;
            &:hover, :active{
                background-color: #E0E0E0;
            }
        }
        .selected{
            background-color: #E0E0E0;
        }
    }
}

.li-selectbox{
    position: relative;
    max-width: 180px;
    width: 100%;
    cursor: pointer;
    .select, .active{
        display: inline-flex;
        height: 40px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 20px;
        border: 1px solid #333;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        span{
            margin-left: 16px;
        }
    }
    .active{
        background-color: $noche;
        color: $white;
    }
    .options{
        position: absolute;
        z-index: 10;
        left: 20px;
        width: calc(100% - 40px);
        margin-top: 3px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        transition: all .5s;
        display: none;
        .option, .selected{
            display: flex;
            width: 100%;
            margin: 0 auto;
            padding: 8px;
            flex-direction: column;
            align-items: flex-start;
            background-color: #F7F6F6;
            color: #1E1E1E;
            text-align: left;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            &:hover, :active{
                background-color: #E0E0E0;
            }
        }
        .selected{
            background-color: #E0E0E0;
        }
    }
    .options.selected {
        display: block;
    }
}

.arrow-down{
    background: url('../../images/icons/arrow-down-orange.svg') no-repeat center center;
    display: block;
    background-size: 100%;
    width: 12px;
    height: 19px;
}

.arrow-up{
    background: url('../../images/icons/arrow-up-orange.svg') no-repeat center center;
    display: block;
    background-size: 100%;
    width: 12px;
    height: 19px;
}