@import 'react-datepicker/dist/react-datepicker.css';

.datepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Bordes, #757575);
    position: relative;
    border-radius: 8px;
    .react-datepicker-wrapper{
        width: 100%;
    }
    input {
        width: 130px;
        padding: 16px !important;
        border-radius: 8px !important;
        padding: 8px;
        border: none;
        border-radius: 4px;
        color: var(--disabled, #9B9B9B);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        cursor: pointer;
    }
}