.availability-wrapper{
    margin: 16px auto 48px;
    width: 100%;
    .availability{
        border-radius: 24px;
        border: 1px solid #777;
        background: $white;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            
            p {
                margin-bottom: 8px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }

            label {
                color: #1E1E1E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 8px;
            }
    
            input[type="number"] {
                border-radius: 8px;
                border: 1px solid #777;
                padding: 16px;
                width: 90px;
                color: var(--text, #1E1E1E);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }
    #instalacionSelect{
        min-width: 240px;
        width: 100%;
    }
}