.alert{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding: 8px 32px 8px 22px;
    flex-direction: row;
    border-radius: 16px;
    margin-left: 30px;
    position: relative;
    margin: 16px auto;
    .text{
        margin-left: 16px;
        p:first-child{
            color: #1E1E1E;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 8px;
        }
        p:last-child{
            color: #1E1E1E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin: 0;
        }
    }
}

.alert-info{
    border-left: 10px solid $solid-blue;
    background-color: $info-blue;
    .symbol{
        background: url('../../images/symbols/info.svg') no-repeat center center;
        display: block;
        width: 92px;
        height: 32px;
    }
}

.alert-success{
    border-left: 10px solid $summer-grass;
    background-color: $info-green;
    .symbol{
        background: url('../../images/symbols/success.svg') no-repeat center center;
        display: block;
        width: 50px;
        height: 32px;
    }
}

.alert-error{
    border-left: 10px solid $red-flag;
    background-color: $info-red;
    .symbol{
        background: url('../../images/symbols/error.svg') no-repeat center center;
        display: block;
        width: 50px;
        height: 32px;
    }
}

.alert-warning{
    border-left: 10px solid $tangerine;
    background-color: $info-warning;
    .symbol{
        background: url('../../images/symbols/warning.svg') no-repeat center center;
        display: block;
        width: 50px;
        height: 32px;
    }
}