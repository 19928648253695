.images-gallery-container {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(73, 88, 103, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;

    .images-gallery-content {
        position: relative;
        padding: 32px;
        border-radius: 24px;
        background-color: #fff;
        max-width: 70%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }

    .image-gallery-content.image-gallery-thumbnails-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 145px);
    }

    .image-gallery-thumbnails {
        padding: 0;
    }

    .image-gallery {
        width: 100%;
        .image-gallery-slide {
            height: calc(100vh - 345px);
            img{
                height: 100%;
            }
        }
        .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-bottom.thumbnails-swipe-horizontal{
            max-width: 90%;
            margin: 0 auto;
        }
        button.image-gallery-thumbnail {
            width: 219px;
            height: 172px;
            margin: 8px;

            span {
                width: 100%;
                height: 100%;
            }

            img {
                height: 100%;
            }
        }
    }

    .close {
        display: flex;
        justify-content: end;
        margin-bottom: 24px;
        button {
            margin: 0;
            cursor: pointer;
        }
    }

    .prev-arrow{
        position: absolute;
        left: 32px;
        bottom: 95px;
        z-index: 300;
        cursor: pointer;
    }

    .next-arrow{
        position: absolute;
        right: 32px;
        bottom: 95px;
        z-index: 300;
        cursor: pointer;
    }

    @media (max-width: 800px) {
        z-index: 300;

        .images-gallery-content {
            border-radius: 16px;
            max-width: inherit;
        }

        .image-gallery-slide-wrapper {
            display: none;
        }

        .image-gallery-thumbnails .image-gallery-thumbnails-container {
            display: flex;
            flex-direction: column;
        }

        .images-gallery-container .image-gallery button.image-gallery-thumbnail {
            margin: 0;
        }

        .prev-arrow {
            display: none;
        }

        .next-arrow {
            display: none;
        }

        .image-gallery-content.image-gallery-thumbnails-bottom {
            height: calc(100vh - 90px);

            .thumbnails-swipe-horizontal {
                max-width: 90%;
                margin: 0 auto;
                max-height: 86%;
                overflow: hidden;
                overflow-y: scroll;
            }
        }

    }
}