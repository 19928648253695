//Buttons header
.bi-button-primary{
    display: flex;
    height: 40px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 20px;
    background: $isidoro-orange;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background-color: $brown-button;
    }
    &:active, &.active{
        background-color: $vereda;
    }
    &:disabled{
        background-color: $cloud-button;
        color: $disabled-grey;
    }
}

.bi-button-tertiary{
    display: flex;
    height: 40px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    color: $isidoro-orange;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 20px;
    border: 1px solid $isidoro-orange;
    background: $white;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background-color: $isidoro-orange;
        color: $white;
    }
    &:active, &.active{
        background-color: $vereda;
    }
    &:disabled{
        background-color: $cloud-button;
        color: $disabled-grey;
        border: none;
    }
}

.bi-button-secondary{
    display: flex;
    height: 40px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid $noche;
    color: $noche;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    border-radius: 20px;
    background: $white;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background-color: $vereda;
        color: $white;
    }
    &:active, &.active{
        background-color: $noche;
        color: $white;
    }
    &:disabled{
        background-color: $white;
        border-color: $disabled-grey;
        color: $disabled-grey;
    }
}

.bi-button-search{
    display: flex;
    height: 40px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #FF6200;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background-color: $red-orange;
        color: $white;
    }
    &:active, &.active{
        background-color: $vereda;
        color: $white;
    }
    &:disabled{
        background-color: $cloud-button;
        color: $disabled-grey;
        .search-icon{
            background: url('../../images/icons/search-icon-gray.svg') no-repeat center center;
            display: block;
            width: 24px;
            height: 24px;
        }
    }
    .search-icon{
        background: url('../../images/icons/search-icon-white.svg') no-repeat center center;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.bi-button-hyperlink{
    color: $link-normal;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-decoration-line: underline;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        color: $hyperblue;
    }
    &:active, &.active{
        color: $noche;
    }
    &:disabled{
        color: $disabled-grey;
    }
}

.bi-button-email{
    background: url('../../images/icons/send-mail.svg') no-repeat center center;
    width: 66px;
    height: 66px;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background: url('../../images/icons/send-mail-hover.svg') no-repeat center center;
    }
}

.bi-button-whatsapp{
    background: url('../../images/icons/whatsapp-icon.svg') no-repeat center center;
    width: 66px;
    height: 66px;
    transition: all .5s;
    cursor: pointer;
    &:hover{
        background: url('../../images/icons/whatsapp-icon-hover.svg') no-repeat center center;
    }
}

.back-button{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        margin-right: 6px;
        margin-bottom: -3px;
        background: url('../../images/icons/arrow-left-orange.svg') no-repeat center center;
        width: 20px;
        height: 20px;
    }
}