.loader{
    position: fixed;
    z-index: 210;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #495867;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading{
        background-image: url('../../images/gifs/loading.gif');
        background-repeat: no-repeat;
        background-position: center;
        width: 118px;
        height: 118px;
        background-size: 100%;
    }
}