.app-container {
    max-width: 1216px;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 800px) {
    .app-container {
        padding-top: 93px;
    }
}