*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button{
    border: none;
    background: none;
    font-family: var(--font-lato);
}

li{
    list-style: none;
}

a{
    text-decoration: none;
}

input:focus, textarea:focus {
    outline: none;
}

input:disabled, .selectDisabled .select{
    background-color: #EEF1F6;
    color: #9B9B9B;
}