$white: #fff;
$black: #000;

$isidoro-orange: #FF6200;
$red-orange: #DB442A;
$watermelon: #F23A6E;
$sea-orchid: #DB2AB7;
$raspberry: #E30B5C;
$ladrillo: #951702;

$horizon-orange: #FF5733;
$coral: #FF5E49;
$baldosa: #DE5601;
$peach: #FF914D;
$tangerine: #F99509;
$lemon: #FFCD29;

$disabled-grey: #708497;
$vereda: #495867;
$noche: #333333;
$grey1: #9B9B9B;
$grey2: #757575;
$hyperblue: #1709B3;

$summer-grass: #14CC69;
$red-flag: #FF230D;

$solid-blue: #2C40F4;
$info-blue: #D1EEFF;
$info-green: #D0FBE4;
$info-red: #FFE4E1;
$info-warning: #FFE3BB;

//buttons
$brown-button: #993B00;
$cloud-button: #EEF1F6;

//hyperlink
$link-normal: #15089C;

//Stepper
$stepper-disabled: #d9d9d9;

$sunlight: radial-gradient(144.91% 134.09% at 3.18% 7.23%, #FF6200 0%, #DB2AB7 100%);
$sunset: radial-gradient(148.17% 137.61% at 0.75% 2.21%, #F23A6E 0%, #FF6200 54.69%, #FFCD29 100%);