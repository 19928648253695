html, body{
    font-family: var(--font-lato);
}

.divider{
    width: 100%;
    height: 0;
    margin: 0 auto;
    border: .5px solid #757575;
}

.vertical-divider{
    border: .5px solid #757575;
}