.stepper-wrapper{
    margin-bottom: 10px;
    padding: 16px;
    .stepper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 124px);
        margin: 0 auto;
        margin-bottom: 4px;
        .bar{
            width: calc(50% - 44px);
            height: 6px;
            margin: 0 2px;
            border-radius: 4px;
            background-color: $stepper-disabled;
        }
        .bar1{
            background-color: $isidoro-orange;
        }
        .bar2{
            position: relative;
        }
    }
    .text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        p:nth-child(1){
            font-weight: 500;
            color: $isidoro-orange;
        }
    }
    .step1{
        background: url('../../images/icons/step1-active.svg') no-repeat center center;
        display: block;
        width: 24px;
        height: 24px;
    }
    .step2{
        background: url('../../images/icons/step2.svg') no-repeat center center;
        display: block;
        width: 24px;
        height: 24px;
    }
    .step3{
        background: url('../../images/icons/step3.svg') no-repeat center center;
        display: block;
        width: 24px;
        height: 24px;
    }
    
}
.stage2{
    .bar1{
        background-color: $isidoro-orange;
    }
    .step1{
        background: url('../../images/icons/step-check.svg') no-repeat center center;
    }
    .step2{
        background: url('../../images/icons/step2-active.svg') no-repeat center center;
    }
    .text p:nth-child(1){
        color: $grey2;
    }
    .text p:nth-child(2){
        font-weight: 500;
        color: $isidoro-orange;
    }
}
.stage3{
    .stepper .bar.bar1, .bar.bar2{
        font-weight: 500;
        background-color: $isidoro-orange;
    }
    .bar2::after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 15%;
        height: 6px;
        background-color: $stepper-disabled;
        border-radius: 0 4px 4px 0;
    }
    .step1, .step2{
        background: url('../../images/icons/step-check.svg') no-repeat center center;
    }
    .text p:nth-child(1){
        color: $grey2;
    }
}
.stage4{
    .stepper .bar.bar1, .bar.bar2{
        background-color: $isidoro-orange;
    }
    .step1, .step2, .step3, .step4{
        background: url('../../images/icons/step-check.svg') no-repeat center center;
    }
    .text p:nth-child(1), p:nth-child(2){
        color: $grey2;
    }
    .text p:nth-child(3){
        font-weight: 500;
        color: $isidoro-orange;
    }
}
.stepper-mobile .stepper-wrapper-mobile {
    margin-bottom: 10px;
    .stepper-fixed {
        position: fixed;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 100;
        top: 90px;
    }
    .stepper {
        display: flex;
        align-items: center;
        margin: 0 auto;
        .bar {
            width: 100%;
            height: 6px;
            background-color: $stepper-disabled;
        }
        .bar1 {
            background-color: $isidoro-orange;
        }
        .bar2 {
            position: relative;
        }
    }
    .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 38px;
        p {
            font-weight: 700;
            color: #FF6200;
            margin: 28px 24px;
            font-size: 18px;
        }
    }

}
.stepper1 {
    .text {
        .text2, .text3 {
            display: none;
        }
    }

    .stepper {
        .bar.bar1 {
            background-color: $isidoro-orange;
        }
    }
}
.stepper2 {
    .text {
        .text1, .text3 {
            display: none;
        }
    }
    .stepper {
        .bar.bar2 {
            background-color: $isidoro-orange;
        }
    }

}